import { node } from "prop-types";
import { createContext, useCallback, useMemo, useRef, useState } from "react";

import scripts from "~lib/googleAds/scripts";

/** @type {import("react").Context<{ loadedGoogleAdScripts: string[], requestLoadScript: (arg0: { pageType: string; locale: string; slug: string; tags?: string[]; }, arg1?: () => void) => void; }>} */
export const GoogleAdsScriptContext = createContext({
  loadedGoogleAdScripts: [],
  requestLoadScript: null,
});

export const GoogleAdsScriptProvider = ({ children }) => {
  const currentlyLoadingScript = useRef(null);
  const [loadedScripts, setLoadedScripts] = useState({});

  // Function for a banner to request loading a script when needed
  // if the script is already loaded or is currently loading, it will not load again
  // See the GoogleAds/Banner component for usage
  const requestLoadScript = useCallback(
    async ({ pageType, locale, slug, tags = [] }) => {
      if (
        loadedScripts[pageType] ||
        currentlyLoadingScript.current === pageType
      ) {
        return;
      }

      if (!scripts[pageType]) {
        console.error(`Script not found for page type: ${pageType}`);
        return;
      }

      // Set the current script type to prevent loading the same script multiple times
      currentlyLoadingScript.current = pageType;

      const { default: scriptLoader } = await scripts[pageType];
      const scriptContent = scriptLoader({ locale, tags, slug });

      // add the script as a script tag directly to the body
      // because nextjs' Script component seems to not run the code synchronously
      const scriptTag = document.createElement("script");
      scriptTag.textContent = scriptContent;
      document.body.appendChild(scriptTag);

      // Add the script to the loaded scripts which will update the state for all components
      // that use this context (only the GoogleAds/Banner component for now)
      setLoadedScripts((prev) => ({ ...prev, [pageType]: scriptContent }));
      currentlyLoadingScript.current = null;
    },
    [loadedScripts],
  );

  const value = useMemo(
    () => ({
      requestLoadScript,
      loadedGoogleAdScripts: Object.keys(loadedScripts),
    }),
    [requestLoadScript, loadedScripts],
  );

  return (
    <GoogleAdsScriptContext.Provider value={value}>
      {children}
    </GoogleAdsScriptContext.Provider>
  );
};

GoogleAdsScriptProvider.propTypes = {
  children: node.isRequired,
};
